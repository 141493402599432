@import "public/static/css/fontStyle.scss";


.o-layout-dashboard__content {
  display: block;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  padding: 2.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.5rem;
  align-self: stretch;
}

.o-layout-dashboard__content::-webkit-scrollbar {
  display: none;
}

.o-overlay {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: var(--overlay);
  position: absolute;
  z-index: 1;
}

.o-title {
  @each $property, $value in $Headline_2 {
    #{$property}: #{$value};
  }
}

.o-content {
  display: flex;
  margin-top: 2.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  align-self: stretch;
}

.o-content__filters {
  display: flex;
  justify-content: space-between;
  align-self: stretch;
}

.o-breadcrumb {
  margin: 1em;

  @each $property, $value in $Body_1 {
    #{$property}: #{$value} !important;
  }
}

.o-center-block {
  width: 250px;
  padding: 10px;
  background-color: #eceadc;
  color: #ec8007;
}

.o-red {
  font-weight: bold;
  color: red;
}

.o-green {
  font-weight: bold;
  color: green;
}

@media (max-width: 900px) {
  .o-layout-dashboard {
    display: initial;
  }
}

/* VIEWER Lateral */
.o-viewer {
  background-color: var(--secondary-light-1);
  padding: 20px 35px;
  position: absolute;
  right: 0;
  top: 76px;
  height: calc(100vh - 76px);
  overflow: scroll;
  width: 350px;
  min-width: 350px;
  max-width: 350px;
}

.o-viewer a {
  color: var(--primary-dark);
  font-size: 12px;
}

.o-viewer .o-btn-outline {
  text-align: left;
}

.o-viewer-logo {
  display: block;
  margin: 320px auto 0 auto;
}

// Custom File
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input {
  position: absolute;
  left: 0;
  cursor: pointer;
  z-index: 0;
}

.custom-file-input::before {
  content: 'Select some files';
  display: inline-block;
  background: linear-gradient(to bottom, #e3e3e3, #f9f9f9);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  text-shadow: 1px 1px #000;
  font-weight: 700;
  font-size: 10pt;
}

.custom-file-input:hover::before {
  border-color: black;
}

.custom-file-input:active::before {
  background: -webkit-linear-gradient(to bottom, #e3e3e3, #f9f9f9);
}

.file-container {
  display: flex;
  justify-content: space-between;
}

.file-container img {
  cursor: pointer;
}

/* INFORMATIONS */

.o-information {
  margin: 0 10px 10px 10px;
}

.o-information-row {
  display: flex;
  gap: 20px;
  margin-bottom: 8px;
}

.o-information-column {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.o-information-flex {
  margin: 15px 0;
  display: flex;
  gap: 8px;
}

.o-information-grid {
  display: grid;
  grid-template-columns: 1fr 350px;
}

.o-information-grid > div:first-child::-webkit-scrollbar {
  display: none;
}

.o-information--block {
  background-color: var(--secondarySurface);
  border-radius: 8px;
  padding: 8px 18px;
  max-width: 600px;
}

.o-information-detail {
  padding: 20px 23px;
  font-size: 14px;
}

.o-information-detail__block {
  display: flex;
  justify-content: space-between;
}

.o-information-detail a {
  color: var(--primary) !important;
  font-size: 13px;
  line-height: 14px;
  text-decoration: underline;
}

.o-information-detail a:hover {
  color: var(--primary-light) !important;
}

.o-information-detail__actions {
  display: grid;
  gap: 12px;
}

.o-information-detail__item {
  display: flex;
}

.o-information-detail__item > div:first-child {
  width: 135px;
  color: var(--onSurface);
}

.o-information-detail__item > div:last-child {
  max-width: 300px;
  color: var(--onSurfaceVariant);
}

.o-information-detail .o-btn-outline {
  text-align: left;
}

.o-information-detail__block a {
  color: var(--primary) !important;
  font-size: 13px;
  line-height: 14px;
  text-decoration: underline;
}

.results-number {
  color: var(--onSurface);
  margin: 0;

  @each $property, $value in $Headline_3 {
    #{$property}: #{$value};
  }
}

.no-results-client-search {
  text-align: center;
  display: flex;
  padding: 2.5rem 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  flex: 1 0 0;
  align-self: stretch;
  opacity: 0.6;
  margin-top: 2.5rem;

  // Styles spécifiques pour les éléments <p> à l'intérieur du conteneur
  p {
    text-align: center;

    @each $property, $value in $Body_1 {
      #{$property}: #{$value};
    }
  }
}
