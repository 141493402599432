.o-isic-card {
  background-color: var(--primary-dark);
  width: 280px;
  border-radius: 10px;
  padding: 17px 17px 2px 17px;

  &-img {
    width: 280px;
    border-radius: 10px;
    padding: 0;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 23px;
  }

  &__number {
    color: var(--onSurface);
    font-weight: bold;
    font-size: 16px;
    margin: 0 10px 20px;
    text-align: center;
  }

  &__label {
    font-size: 10px;
    font-weight: bold;
    margin: 0 10px;
  }

  &__name {
    font-size: 12px;
    font-weight: bold;
    color: var(--onSurface);
    margin: 0 10px;
  }
}
