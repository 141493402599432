@import "public/static/css/fontStyle.scss";

/* INPUT */
.o-input-group {
  position: relative;
  align-self: stretch;
}

.o-input-group > label { //TODO: remplacer les z-index par display none
  color: var(--secondary-mid-1);
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 5px;
  transition: 0.1s ease all;
  -moz-transition: 0.1s ease all;
  -webkit-transition: 0.1s ease all;
}

.o-input {
  display: block;
  width: 100%;
  min-height: 38px;
  padding: 0 10px;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid var(--primary);
  background-color: transparent;
  color: var(--onSurface);
  box-sizing: border-box;
  resize: none;

  &::placeholder {
    color: var(--onSurfaceVariant);
    z-index: 1114;
    background-color: var(--background);

    @each $property, $value in $Body_1 {
      #{$property}: #{$value};
    }
  }

  &:focus {
    outline: none;
    border: 1px solid var(--primary);
  }

  &:not(:focus) + .o-input:not(:placeholder-shown) {
    border-bottom: 1px solid var(--secondary);
  }

  &:focus ~ label,
  &:not(:placeholder-shown) ~ label {
    top: -16px;
    font-size: 10px;
    //background-color: inherit;
  }

  &::placeholder {
    font-size: 14px;
  }

  /* when focused the input hide placeholder */
  &:focus::placeholder {
    opacity: 0;
  }

  /* INPUT DISABLED */
  &:disabled {
    border: 1px solid var(--secondary-mid-2);
    color: var(--secondary-mid-2);
    cursor: not-allowed;
  }

  &:disabled ~ label {
    color: var(--secondary-mid-2);
  }

  &:disabled ~ .o-input-subtext {
    color: var(--secondary-mid-2);
  }

  /* type number */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
}

/* INPUT SUPPORT TEXT */
.o-input-subtext {
  color: var(--secondary-mid-1);
  bottom: -20px;
  font-size: 10px;
}

/* INPUT ERROR */
.o-input-error {
  > textarea,
  > input {
    border: 1px solid var(--error);
  }

  > span {
    color: var(--error);
  }

  > textarea:focus ~ label,
  > textarea:not(:placeholder-shown) ~ label,
  > input:focus ~ label,
  > input:not(:placeholder-shown) ~ label {
    position: absolute;
    top: -15px;
    font-size: 10px;
    color: var(--error);
  }
}

// Gestion du backround pour les label dans 'Surveillance'
.o-input-group.filter > label {
  background-color: var(--background);
}

// Gestion du backround pour les label Date dans 'Rapports'
.o-input-group.undefined.mb-2.ml-5 > label {
  background-color: var(--background);
}

// Gestion du background pour les label dans 'client_search'
.input-client-search {
  min-width: 520px;
}

.o-input-group.client-search > label {
  background-color: var(--background);
}
