@import "public/static/css/fontStyle.scss";

.o-head {
  display: flex;
  justify-content: space-between;
  top: 0;
  align-items: center;
  align-self: stretch;
  margin-bottom: 1rem;

  @media (max-width: 900px) {
    position: static;
    margin-bottom: 70px;
    padding: 30px 5% 0;

    .o-icon-hamburger {
      display: inline-block;
      margin-right: 15px;
    }

    .o-icon-head {
      display: none;
    }

    .o-head__search {
      position: absolute;
      top: 100px;
      margin-left: 0;
      left: 35px;
    }

    .o-content {
      padding: 0 5%;
    }
  }

  @media (max-width: 650px) {
    .o-head__search {
      left: 15px;
    }
  }
}

.o-head__search {
  margin-left: 50px;
}

.o-icon-hamburger {
  margin-right: 40px;
  display: none;
}

.o-head_search_client {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.75rem;
  align-self: stretch;
}

.o-head-content {
  display: flex;
}

.o-head-title {
  color: var(--onSurface);
  margin-bottom: 0;

  @each $property, $value in $Headline_1 {
    #{$property}: #{$value};
  }
}

.o-head-title a {
  color: var(--onSurfaceVariant);
  opacity: 72%;
  transition: color 0.25s;
  text-decoration: none;

  &:hover {
    color: var(--onPrimaryContainer);
    text-decoration: none;
  }
}
