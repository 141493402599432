@import "public/static/css/fontStyle.scss";

/* TABLE */
.o-table {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  border: 0.5px solid var(--stroke);
  border-radius: 0.5rem;
  color: var(--onSurface);
  width: 100%;
  table-layout: auto;
  height: initial !important;

  &::-webkit-scrollbar {
    display: none;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  thead tr {
    display: table-row;
    width: 100%;
    padding: 1rem 1.5rem;
    border-bottom: 0.5px solid var(--stroke);
  }

  tbody tr {
    border-bottom: 0.5px solid var(--stroke);
    background-color: var(--background);
    display: table-row;
    width: 100%;
    padding: 1rem 1.5rem;
    align-items: center;

    &:hover {
      background-color: var(--secondarySurface);
    }
  }

  th, td {
    display: table-cell;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    padding: 1rem;

    @each $property, $value in $Body_1_Stronger {
      #{$property}: #{$value};
    }
  }

  .th-text-icon {
    display: inline-flex;
    position: relative;
    align-items: center;
    gap: 0.5rem;
  }

  .selected {
    color: var(--onPrimaryContainer);
  }

  .th-text-icon button {
    outline: 0;
  }

  td {
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    padding: 1rem;

    @each $property, $value in $Body_1 {
      #{$property}: #{$value};
    }
  }

  &.cursor-default {
    td {
      cursor: default;
    }
  }

  &__left {
    tr th,
    tr td {
      text-align: left !important;
    }
  }
}

.table-title {
  margin-bottom: 16px;
  color: var(--onSurface);
  margin-top: 16px;

  @each $property, $value in $Headline_3 {
    #{$property}: #{$value};
  }
}

/* TABLE MOBILE */
.o-table-mobile {
  &__items {
    font-size: 12px;
    color: var(--black);
    display: grid;
    line-height: 1;
    border-bottom: 0.5px solid var(--stroke);
    padding: 10px 0;
  }

  &__item {
    display: flex;
    justify-content: space-between;
  }

  &__column,
  &__element {
    font-family: Poppins, sans-serif;
    font-weight: 500;
    width: 130px;
    max-width: 130px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 5px 0;

    &.element {
      text-align: right;
      width: 150px;
      max-width: 150px;
    }
  }
}

.o-table-icon {
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
}

.o-badge {
  background-color: var(--secondary-mid-1);
  border-radius: 11px;
  color: var(--onSurface);
  font-size: 10px;
  padding: 4px 10px;
  margin: 4px 5px;
  height: 22px;
}

.o-table-element {
  &__list {
    display: flex;
    flex-wrap: wrap;
    overflow-x: scroll;

    @media (max-width: 900px) {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

@media (min-width: 900px) {
  .o-table {
    height: calc(100vh - 150px);
    width: 100%;

    th {
      position: sticky;
      top: 0;
    }

    .o-table-line {
      width: 100%;
      height: 1px;
      background-color: var(--secondary-mid-2);
      position: absolute;
      top: 117px;
      margin: 0 -60px;
    }
  }
}

.o-table-container {
  width: 100%;
}

.o-table-contain {
  width: 100%;
}

.o-btn-table {
  &__disabled {
    cursor: default;
  }
}

.div-loading {
  width: 100%;
  align-items: center;
  justify-content: center;
}
