@import "public/static/css/fontStyle.scss";

.previewNoteItem__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0em;
    margin: 0;
    align-items: center;
    justify-content: space-between;
    color: var(--onSurfaceVariant);
    &__title {
        color: var(--onSurfaceVariant);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 0 0 0.5em 0;
        @each $property, $value in $Body_1 {
            #{$property}: #{$value};
        }
        .operator {
            color: var(--primary);
            text-decoration: underline;
        }
    }
    &__value {
        align-items: center;
        justify-content: space-between;
        border-radius: 0.5em;
        background-color: var(--background);
        padding: 1em;
        width: 100%;
        @each $property, $value in $Body_1 {
            #{$property}: #{$value};
        }
    }
}
