.o-gae-service-card {
  background-color: var(--surface);
  width: 200px;
  border: 1px solid var(--stroke);
  border-radius: 5px;
  padding: 5px;

  &-img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
    padding: 0;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 23px;
  }

  &__name,
  &__label,
  &__number {
    font-size: 16px;
    font-weight: bold;
    margin: 0 5px;
    text-align: center;
  }

  &__label,
  &__number {
    font-size: 14px;
  }

  &__number {
    font-weight: normal;
  }
}
