@import "public/static/css/fontStyle.scss";

/*TABLE PAGINATION*/
.o-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin-top:2.5rem;
  color: var(--onSurfaceVariant);
    @each $property, $value in $Body_1 {
      #{$property}: #{$value};
    }
  
  &__elements_per_page {
    display: flex;
    align-items: center;
    gap: 1rem;
    line-height: 1.125rem!important;
    

    &__num {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &__page {
    display: flex;
    align-items: center;
    gap: 1rem;

    &__button {
      display: flex;
      align-items: flex-start;
      gap: 0.5rem;
    }
  }
}
