@import "public/static/css/fontStyle.scss";

.o-tooltip {
  @each $property, $value in $Tooltip {
    #{$property}: #{$value};
  }
  
  p, a {
    @each $property, $value in $Tooltip {
      #{$property}: #{$value};
    }
  }
}