@import "../../../public/static/css/fontStyle.scss";

.infoItem__container {
    display: flex;
    width: 100%;
    padding: 0.5em;
    justify-content: space-between;
    align-items: center;
    &.end {
        border-bottom: none;
    }
    &.trustlevel {
        background-color: var(--secondarySurface);
        border-radius: 0.5em;
        padding: 1em;
    }
    .infoItem {
        &__title {
            color: var(--onSurface);
            flex: 1 0 0;
            min-width: 30%;
            max-width: 50%;
            @each $property, $value in $Body_1_Strong {
                #{$property}: #{$value};
            }
        }
        &__value {
            color: var(--onSurface);
            flex: 1 0 0;
            text-align: left;
            min-width: 50%;
            max-width: 70%;
            @each $property, $value in $Body_1 {
                #{$property}: #{$value};
            }
            &.right {
                text-align: right;
            }
        }
    }
}
