@import "public/static/css/fontStyle.scss";

.o-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  border-radius: 0.5rem;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  line-height: 20px;
  padding: 0 35px;
  color: var(--onButton);
  border: none;

  &:focus {
    outline: none;
  }
}

.o-btn__icon {
  svg {
    width: 1rem;
    height: 1rem;
  }
}

.o-btn-none {
  height: auto;
  border-radius: 5px;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  line-height: 1;
  padding: 2px 20px;
  color: none;
  background-color: transparent;
  border: none;
}

.o-btn-default {
  background-color: var(--button);
  color: var(--onButton);
  gap: 0.5rem;

  &:hover {
    background-color: var(--hoverButton);
    color: var(--onButton);
  }

  &:disabled {
    background-color: var(--disabledButton);
    color: var(--onDisabledButton);
    cursor: not-allowed;
    opacity: 1;
  }
}

.o-btn-outline {
  background-color: var(--button);
  color: var(--onButton);
  display: flex;
  padding: 0.5rem;
  margin: 0.5rem;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background-color: var(--hoverButton);
    color: var(--onButton);
  }

  &:disabled {
    background-color: var(--disabledButton);
    color: var(--onDisabledButton);
    cursor: not-allowed;
    opacity: 1;
  }
}

.o-btn-text {
  &:focus {
    outline: none;
    box-shadow: none;
    padding: 0;
    margin: 0 35px;
  }
}

.o-btn-icon {
  display: flex;
  padding: 0.25rem 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--background);
  border: 1px solid var(--onSurfaceVariant);
  color: var(--onSurfaceVariant);
  border-radius: 0.25rem;

  &:hover,
  &.active {
    outline: none;
    background: var(--onSurface);
    color: var(--background);
  }

  &:disabled {
    background-color: var(--disabledButton);
    color: var(--onDisabledButton);
    cursor: not-allowed;
    opacity: 1;
  }
}

.o-btn-outline.warning, .o-btn-default.warning {
  background-color: var(--errorSurface);
  color: var(--errorOnSurface);
  border: 1px solid var(--errorOnSurface);

  &:hover {
    background-color: var(--deleteHoverButton);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .img {
    color: var(--errorOnSurface);
  }
}

.cancel {
  background-color: var(--secondaryButton);
  color: var(--onSurface);
  border: 1px solid var(--secondaryButton);

  &:hover {
    border: 1px solid var(--onSurface);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .img {
    color: var(--errorOnSurface);
  }
}

// Modification du chevron et du container (div chevron)
.chevron-button {
  background-color: transparent;
  align-items: center;
  border: none;
  outline: none;
  position: relative;
  z-index: 1;
}

.chevron-button svg {
  width: 1rem;
  height: 1rem;
  fill: var(--stroke);
}

.chevron-button-circle {
  position: absolute;
  display: flex;
  gap: 45px;
  top: 55%;
  margin-left: 8px;
  transform: translate(-50%, -50%);
  background-color: var(--primaryContainer);
  border-radius: 1.5rem;
  height: 25px;
  width: 25px;
  z-index: -1;
}

.o-btn.o-btn-default.search {
  display: inline-flex;
  margin-bottom: 25px;
  margin-left: 60px;
}

.receiver-payin-button {
  border: none;
  background-color: transparent;
}

// Gestion du bouton search dans 'client_search'
.o-btn.o-btn-default.button-search {
  display: flex;
  padding: 0.5rem 0.75rem;
  justify-content: center;
  align-items: center;
}

// Gestion du cancel sur les Pop-in "Informations clients"
.icon-cancel {
  position: absolute;
  gap: 10px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--primaryContainer);
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  gap: 0.625rem;
  //margin-left: 400px;
  right: 16px;
  top: 16px;
}

.icon-cancel:hover {
  background-color: var(--primary);
}

// Gestion du bouton confirmer pour la validation dans les pop-in d'"Informations client"
.o-btn.o-btn-default.confirm-infos {
  width: 100%;
  padding: 8px 12px 8px 12px;
}

.o-btn-road {
  display: flex;
  padding: 0.5rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin: 0;
  color: var(--onButton);
  @each $property, $value in $Button {
    #{$property}: #{$value};
  }
  border-radius: 0.5rem;
  background-color: var(--button);

  &:hover {
    background-color: var(--hoverButton);
    color: var(--onButton);
    text-decoration: none !important;
  }

  &:disabled {
    background-color: var(--disabledButton);
    color: var(--onDisabledButton);
    cursor: not-allowed;
  }
}

.o-btn-road__text {
  &:hover {
    text-decoration: none;
  }
}
