.o-supervision {
  height: 100vh;
  background-color: var(--black);
  overflow-x: hidden;
  padding-top: 20px;
  display: grid;
  padding: 6px 0 20px 0;
  z-index: 2;
  grid-area: sidebar;
}

.o-supervision-item {
  padding: 10px 30px;
  text-decoration: none;
  font-size: 13px;
  gap: 0.5rem;
  color: var(--onSurface);
  display: flex;
  align-items: center;
  line-height: 1;
  height: 34px;
}

.o-supervision-item > img {
  margin-right: 5px;
  width: 20px;
  height: 17px;
}

.o-supervision-item:hover {
  text-decoration: none;
  color: var(--onPrimaryContainer);
  transition: all 0.25s;
}
