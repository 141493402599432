@import "public/static/css/fontStyle.scss";

/* Dropdown label styles */
.o-dropdown {
  position: relative;

  &:focus-within .o-dropdown__label,
  &.has-value .o-dropdown__label {
    top: -15px;
    font-size: 10px;
    color: var(--primary);
  }

  &:focus-within .o-dropdown__label {
    color: var(--primary);
  }

  &.error {
    .o-dropdown__label {
      color: var(--error);
    }
  }
}

.o-dropdown-label-not-focus {
  color: var(--onSurfaceVariant)!important;
  font-weight: normal;
  background-color: var(--background);
  position: absolute;
  pointer-events: none;
  padding : 0 2px;
  left: 0.5rem;
  top: 9px;
  transition: 0.1s ease all;
  -moz-transition: 0.1s ease all;
  -webkit-transition: 0.1s ease all;

@each $property, $value in $Body_1 {
  #{$property}: #{$value};
}
}

.o-dropdown-label {
  color: var(--onSurfaceVariant)!important;
  font-size: 10px!important;
  font-weight: normal;
  background-color: var(--background);
  position: absolute;
  pointer-events: none;
  padding : 0 2px;
  left: 0.7rem;
  top: -14px;
  transition: 0.1s ease all;
  -moz-transition: 0.1s ease all;
  -webkit-transition: 0.1s ease all;

@each $property, $value in $Body_1 {
  #{$property}: #{$value};
}
}

.o-dropdown-message {
  color: var(--onSurface);
  font-size: 10px;
  font-weight: normal;
  margin: 0;
}

.o-dropdown-error {
  .o-dropdown-message,
  .o-dropdown-label {
    color: var(--error); 
  }

  textarea {
    border-bottom: 2px solid var(--error);
  }

  span {
    color: var(--error);
  }

  textarea:focus ~ label,
  textarea:not(:placeholder-shown) ~ label {
    position: absolute;
    top: -15px;
    font-size: 10px;
    color: var(--error);
  }
}

.state-dropdown {
  position: relative;
  border: none;

  button {
    border: none;
    background: none;
  }
}

.panel {
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  left: -60px;
  top: 30px;
  gap: 0.75rem;
  position: absolute;
  background: var(--background); 
  border-radius: 0.5rem; 
  border: solid 0.5px var(--stroke); 
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 8px 0px rgba(27, 27, 27, 0.03);

  fieldset {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  label {
    overflow: hidden;
    color: var(--onSurface);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    margin-bottom: 0;

    @each $property, $value in $Filter_Panel {
      #{$property}: #{$value};
    }
  }
}

/* Checkbox personnalisée */
input[type="checkbox"] {
  appearance: none; // Retirer le style par défaut de la checkbox
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 1rem;
  height: 1rem;
  border-radius: 0.125rem;
  border: 1px solid var(--stroke); 
  position: relative;
  cursor: pointer;

  &:before {
    content: ''; // fond de la checkbox
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    border-radius: 1px;
  }

  &:checked:before {
    background-color: var(--primary); 
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='-1 1 20 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Group'%3E%3Cpath id='Vector' d='M5.16613 11.7748C4.71195 11.775 4.27638 11.5944 3.9555 11.273L0.295374 7.61425C-0.098458 7.22029 -0.098458 6.58169 0.295374 6.18773C0.689332 5.7939 1.32794 5.7939 1.72189 6.18773L5.16613 9.63196L14.2781 0.519983C14.6721 0.126151 15.3107 0.126151 15.7046 0.519983C16.0985 0.913941 16.0985 1.55255 15.7046 1.9465L6.37675 11.273C6.05587 11.5944 5.6203 11.775 5.16613 11.7748Z' fill='%23FEFEFE'/%3E%3C/g%3E%3C/svg%3E%0A");
  }

  &:after {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='-1 1 20 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Group'%3E%3Cpath id='Vector' d='M5.16613 11.7748C4.71195 11.775 4.27638 11.5944 3.9555 11.273L0.295374 7.61425C-0.098458 7.22029 -0.098458 6.58169 0.295374 6.18773C0.689332 5.7939 1.32794 5.7939 1.72189 6.18773L5.16613 9.63196L14.2781 0.519983C14.6721 0.126151 15.3107 0.126151 15.7046 0.519983C16.0985 0.913941 16.0985 1.55255 15.7046 1.9465L6.37675 11.273C6.05587 11.5944 5.6203 11.775 5.16613 11.7748Z' fill='%23FEFEFE'/%3E%3C/g%3E%3C/svg%3E%0A");
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
    position: absolute;
    transform: translate(-50%, -50%);
  }

  &:checked:after {
    opacity: 1;
    transform: scale(1);
  }
}
