@import "../../../public/static/css/fontStyle.scss";

.medium {
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.25rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    flex-shrink: 0;
    border-radius: 22.5rem;
    .avatar__text {
        @each $property, $value in $Initials_2 {
            #{$property}: #{$value};
        }
    }
    &.avatar__empty {
        border-radius: 17.5rem;
        border: 1.5px dashed var(--stroke);
    }
}

.large {
    display: flex;
    width: 3.5rem;
    height: 3.5rem;
    padding: 0.25rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    flex-shrink: 0;
    border-radius: 17.5rem;
    .avatar__text {
        @each $property, $value in $Initials_1 {
            #{$property}: #{$value};
        }
    }
    &.avatar__empty {
        border-radius: 17.5rem;
        border: 1.5px dashed var(--stroke);
    }
}

.userProfil__container__icon {
    position: relative;
    &__company {
        position: absolute;
        bottom: -0.3rem;
        right: -0.3rem;
        width: 1.5rem;
        height: 1.5rem;
    }
}
