@import "public/static/css/fontStyle.scss";

.o-modal {
  position: fixed;
  padding: 50px 0;
  z-index: 1116;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: var(--black);
  background-color: var(--overlay);
  animation: opac 0.1s;
  display: flex;
  align-items: center;
}

.o-modal-content {
  background-color: var(--background);
  margin: auto;
  width: auto;
  max-width: 100%;
  height: auto;
  border-radius: 0.5rem;
  max-height: 75vh;
  overflow-y: auto;
  box-sizing: border-box;
  text-align: left;
  box-shadow: 5px 5px 5px 0px var(--dropdownShadow);
}

.o-modal-info {
  display: flex;
  position: relative;
  padding: 3rem 2rem 2rem 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  &__title-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    align-self: stretch;
    color: var(--onSurface);

    &__text {
      @each $property, $value in $Body_1 {
        #{$property}: #{$value};
      }
    }
  }

  &__fields {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;

    &__checkbox {
      display: flex;
      align-items: center;
    }
  }
}

.o-input {
  background-color: var(--background);
  display: flex;
  padding: 0.5rem 0.75rem;
  align-items: center;
  align-self: stretch;
  border-radius: 0.5rem;
  border: 1px solid var(--stroke);
  height: 2.1rem;
}

.o-input-group label {
  color: var(--onSurfaceVariant) !important;
  line-height: 1.125rem;
  background-color: var(--background);
  z-index: 1111;
  margin-top: 0.2rem;
  padding: 0 2px;
  
  @each $property, $value in $Body_1 {
    #{$property}: #{$value};
  }
}

.o-btn-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

@keyframes opac {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (min-width: 500px) {
  .o-modal .o-btn {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 900px) {
  .o-modal-content {
    width: 95vw;
  }
}
