.o-status {
  height: 100vh;
  background-color: var(--black);
  overflow-x: hidden;
  padding-top: 20px;
  padding: 6px 0 20px 0; 
  z-index: 2;
  grid-area: sidebar;
}

.o-status-item {
  padding: 10px 30px;
  text-decoration: none;
  font-size: 13px;
  color: var(--secondary);
  display: flex;
  align-items: center;
  line-height: 1;
  height: 34px;
}

.o-status-item > img {
  margin-right: 5px;
  width: 20px;
  height: 17px;
}

.o-status-item:hover {
  text-decoration: none;
  color: var(--primary-dark);
  transition: all 0.25s;
}
