.login {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
  }
}

.login-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.login-button {
  padding: 14px 40px;
  border-radius: 4px;
  border: none;
  background-color: var(--white);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  color: var(--secondary);
}

.login-right img {
  height: 100vh;
  width: 100%;
  object-fit: cover;

  @media (max-width: 900px) {
    height: 500px;
  }
}
