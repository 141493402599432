.form-detail {
  display: flex;
  max-width: 1000px;
  margin: 0px 50px;

  @media (max-width: 850px) {
    display: initial;
  }
}


.form-detail > div {
  width: 100%;
}

.form-detail > div:first-child {
  margin-right: 10%;
}


.form-group {
  gap: 12px;
  margin-top: 2.5rem;

  input {
    &::placeholder {
      color: var(--onSurfaceVariant);
    }
  }
}
