@import "../../../public/static/css/fontStyle.scss";

.blocWithTitle__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 8px;
    padding: 8px 18px;
    margin: 0px 0px 10px 0px;
    gap: 0.5rem;
    background: var(--secondarySurface);
    &__title {
        color: var(--onPrimaryContainer);
        @each $property, $value in $Headline_3 {
            #{$property}: #{$value};
        }
    }
}
