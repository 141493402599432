
.svg-loader {
    display: flex;
    align-content: space-around;
    justify-content: center;
  }
  
  .svg-container {
    display: block; 
  }
  
  .loader-svg {
    position: absolute;
    left: 0; right: 0; top: 0; bottom: 0;
    fill: none;
    stroke-width: 5px;
    stroke-linecap: round;
    stroke: var(--primary);
  }
  
  .loader-svg.bg {
    stroke-width: 8px;
    stroke: var(--stroke); 
  }
  
  .animate {
    stroke-dasharray: 282.6;
    animation: fill-animation 1s cubic-bezier(1,1,1,1) 0s infinite;
  }
  
  @keyframes fill-animation {
    0% {
      stroke-dasharray: 40 282.6;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 141.3;
      stroke-dashoffset: 141.3;
    }
    100% {
      stroke-dasharray: 40 282.6;
      stroke-dashoffset: 282.6;
    }
  }
