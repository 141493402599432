@import "public/static/css/fontStyle.scss";

.atd_content {
  display:flex;
  flex-direction: column;
  background-color: var(--secondarySurface);
  border-radius: 8px;
  gap: 1em;
  padding: 1em;
  width:70%;
  &__title {
    color: var(--onSurfaceVariant);
    margin:0 0 0 1em;
    vertical-align: middle;
    @each $property, $value in $Body_1_Stronger {
      #{$property}: #{$value};
    }          
  }
  &__warning {
    display:flex;
    flex-direction: row;
    align-items: center;
    color: var(--errorOnSurface);
    @each $property, $value in $Body_1 {
      #{$property}: #{$value};
    }
    &__icon {
      margin: 0 1em 0 1em;
    }
  }
}