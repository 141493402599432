@import "public/static/css/fontStyle.scss";

.previewWalletItem__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1em;
    align-items: center;
    justify-content: space-between;
    background-color: var(--background);
    border-radius: 0.5em;
    &__title {
        color: var(--onSurfaceVariant);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        @each $property, $value in $Headline_3 {
            #{$property}: #{$value};
        }
        .status{
            align-items: flex-end;
            border-radius: 1em;
            padding: 0.4em;
            @each $property, $value in $Headline_4 {
                #{$property}: #{$value};
            }
            &.pending {
                @each $property, $value in $Container_Warning {
                    #{$property}: #{$value};
                }
            }
            &.validated {
                @each $property, $value in $Container_Success {
                    #{$property}: #{$value};
                }            
            }
            &.canceled {
                @each $property, $value in $Container_Error {
                    #{$property}: #{$value};
                }
            }
        }
    }
    &__value {
        align-items: center;
        justify-content: space-between;
        width: 100%;
        
    }
}
