@import "public/static/css/fontStyle.scss";

.previewAlertItem__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1em;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.5em;
    background-color: var(--surfaceSelected);
    color: var(--onSurfaceVariant);
    border-style: solid;
    &__title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        @each $property, $value in $Body_1 {
            #{$property}: #{$value};
        }
        .status{
            align-items: flex-end;
            border-radius: 1em;
            padding: 0.4em;
            @each $property, $value in $Headline_4 {
                #{$property}: #{$value};
            }
        }
    }
    &__value {
        align-items: center;
        justify-content: space-between;
        width: 100%;
        @each $property, $value in $Body_1 {
            #{$property}: #{$value};
        }        
    }
    &.new {
        @each $property, $value in $Container_Warning {
            #{$property}: #{$value};
        }          
    }
    &.info_asked {
        @each $property, $value in $Container_Warning {
            #{$property}: #{$value};
        }
    }
    &.checked_ok {
        @each $property, $value in $Container_Success {
            #{$property}: #{$value};
        }           
    }
    &.fraud {
        @each $property, $value in $Container_Error {
            #{$property}: #{$value};
        }           
    }
}
