@import "public/static/css/fontStyle.scss";

.o-preview__monitoring_subTitle {
  display:flex;
  flex-direction: row;
  color: var(--onSurfaceVariant);

  @each $property, $value in $Headline_4 {
    #{$property}: #{$value};
  }
  a {
    margin-left: 1em;
    font-weight: normal;
  }
}