@import "public/static/css/fontStyle.scss";

.clientHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2.5rem;
  align-self: stretch;
  min-width: var(--minContentWidth);
  max-width: var(--maxContentWidth);
  &__loading {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      height: 100%;
  }
  &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      &__info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          &__left {
              display: flex;
              padding: 0.5rem 0rem;
              align-items: center;
              gap: 0.75rem;
              &__title {
                display: flex;
                flex-direction: row;
                align-items: center;
                @each $property, $value in $Headline_1 {
                  #{$property}: #{$value};
                }
                .blocked {
                    background-color: var(--errorBackground);
                    color: var(--error);
                    border-radius: 1.5em;
                    margin: 0 0 0 1em;
                    padding: 0.2em 1em ;
                    @each $property, $value in $Body_1 {
                        #{$property}: #{$value};
                    }
                }
                .status {
                    border-radius: 1.5em;
                    margin: 0 0 0 1em;
                    padding: 0.2em 1em ;
                    @each $property, $value in $Body_1 {
                        #{$property}: #{$value};
                    }
                }
                .onboarding {
                    background-color: var(--statusOnboarding);
                    color: var(--statusOnOnboarding);
                }
                .onboarding_finalized {
                    background-color: var(--statusOnboardingFinalized);
                    color: var(--statusOnOnboardingFinalized);
                }
                .onboarding_waiting_payin {
                    background-color: var(--statusOnboardingWaitingPayin);
                    color: var(--statusOnOnboardingWaitingPayin);
                }
                .kyc_reviewing {
                    background-color: var(--statusKycReviewing);
                    color: var(--statusOnKycReviewing);
                }
                .kyc_review_ask_more {
                    background-color: var(--statusKycReviewAskMore);
                    color: var(--statusOnKycReviewAskMore);
                }
                .kyc_review_refused {
                    background-color: var(--statusKycReviewRefused);
                    color: var(--statusOnKycReviewRefused);
                }
                .canceled {
                    background-color: var(--errorBackground);
                    color: var(--error);
                }
                .company {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    background-color: var(--userSurface3);
                    color: var(--userOnSurface3);
                    margin: 0 0 0 1em;
                    padding: 0.2em 1em ;
                    border-radius: 1.5em;
                    cursor: pointer;
                    @each $property, $value in $Body_1 {
                        #{$property}: #{$value};
                    }
                    &__icon{
                        width: 16px;
                    }
                }
              }
              &__text {
                  color: var(--onSurface);
                  margin: 0.5em 0em 0em 0em;
                  @each $property, $value in $Body_1 {
                      #{$property}: #{$value};
                  }
              }
          }
      }
  }
  &__btns {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: 1rem;
      align-self: stretch;
  }
}
