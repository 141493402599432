@import "public/static/css/fontStyle.scss";

.o-content__detailclient {
  @each $property, $value in $User_Body_Container {
    #{$property}: #{$value};
  }
  &__title{
    display: flex;
    flex-direction: row;
    margin: 1em;
    color: var(--onSurfaceVariant);
    justify-content: space-between;
    @each $property, $value in $Headline_3_Lighter {
      #{$property}: #{$value};
    }
  }
  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 8px;
    &__column {
      display: flex;
      flex-direction: column;
      align-self: stretch;
      gap: 8px;
      width: 100%;
    }
  }
}