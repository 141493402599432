@import "public/static/css/fontStyle.scss";

.o-filter {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;

  a,
  a:visited {
    text-decoration: none !important;
  }
}

.o-filter__tabs {
  display: flex;
  align-items: flex-start;
  gap: 1rem;

  @each $property, $value in $Body_1 {
    #{$property}: #{$value};
  }
}

.o-filter__tabs__tab {
  color: var(--onSurfaceVariant);
  text-decoration: none;
  display: flex;
  padding: 0.5rem 0.75rem;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  position: relative;

  &.active {
    color: var(--primary);

    &::after {
      content: '';
      width: 98%;
      height: 0.0625rem;
      position: absolute;
      bottom: 0rem;
      background-color: var(--primary);
      transform: translateX(-50%);
      left: 50%;
    }
  }
  &:hover {
    background-color: var(--surfaceSelected);
    border-radius: 5px;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown:hover .dropdown-content {
  display: block;
}

// .dropdown:hover {
//   background-color: var(--surfaceSelected);
// }

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--secondarySurface);
  min-width: 200px;
  box-shadow: 5px 5px 5px 0px var(--dropdownShadow);
  border-radius: 5px;
  z-index: 1;
}

.dropdown-content a {
  color: var(--onSurfaceVariant);
  padding: 10px 10px;
  text-decoration: none;
  display: block;
  border-radius: 5px;
  &.active {
    color: var(--primary);
  }
}

.dropdown-content a:hover {
  background-color: var(--surfaceSelected);
}
