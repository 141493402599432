@import "public/static/css/fontStyle.scss";

.o-navbar {
  display: flex;
  width: 100%;
  position: sticky;
  top: 0;
  height: 4.5rem;
  padding: 0 2.5rem;
  justify-content: space-between;
  align-items: center;
  z-index: 2000;
  border-bottom: 0.5px solid var(--stroke);
  background: var(--background);

  a,
  a:visited {
    text-decoration: none !important;
  }

  @each $property, $value in $Body_1 {
    #{$property}: #{$value};
  }
}

.o-navbar__left {
  display: flex;
  align-items: center;
  gap: 3rem;
  flex: 1 0 0;
  align-self: stretch;
}

.o-navbar__left__logo__title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.o-navbar__menu {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  align-self: stretch;

  .o-navbar__header__item {
    display: flex;
    padding: 0.5rem;
    align-items: center;
    gap: 0.25rem;
    color: var(--onSurface);
    position: relative;

    &:hover {
      color: var(--onPrimaryContainer);
    }

    &.active {
      color: var(--onPrimaryContainer);

      &::after {
        content: '';
        width: 0.25rem;
        height: 0.25rem;
        position: absolute;
        bottom: 0rem;
        background-color: var(--onPrimaryContainer);
        border-radius: 50%;
        transform: translateX(-50%);
        left: 50%;
      }
    }
  }
}

.o-navbar__right {
  display: flex;
  align-items: center;
  gap: 1.5rem;

  &__admin {
    display: flex;
    padding: 0.2rem 0.75rem;
    align-items: center;
    gap: 0.25rem;
    border-radius: 1rem;
    outline: 1px solid var(--onSurfaceVariant);

    &__text {
      color: var(--onSurfaceVariant);
    }

    &__lock {
      height: 1rem;
      width: 1rem;
      fill: var(--onSurfaceVariant);
    }
  }

  &__profil {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &__icon {
      display: flex;
      width: 2.5rem;
      height: 2.5rem;
      padding: 0.25rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.25rem;
      border-radius: 22.5rem;
      background: var(--primary);
      color: var(--onButton);
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1rem;

      &__chevron {
        height: 1rem;
        width: 1rem;
        fill: var(--onSurfaceVariant);

        &__dropdown {
          position: relative;
          border: none;

          &__panel {
            display: flex;
            padding: 0.5rem;
            flex-direction: column;
            align-items: flex-start;
            left: -250px;
            top: 20px;
            gap: 2.5rem;
            position: absolute;
            z-index: 1000;
            background: var(--background);
            border-radius: 0.5rem;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 8px 0px rgba(27, 27, 27, 0.03);

            &__sup {
              display: flex;
              padding: 0.5rem 0.5rem 1rem 0.5rem;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 0.5rem;
              align-self: stretch;
              border-bottom: solid 0.5px var(--stroke);

              &__title {
                color: var(--onSurface);

                @each $property, $value in $Label_Strong {
                  #{$property}: #{$value};
                }
              }

              &__user {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                align-self: stretch;

                &__info {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  flex: 1 0 0;

                  &__name {
                    @each $property, $value in $Body_1 {
                      #{$property}: #{$value};
                    }
                  }

                  &__company {
                    @each $property, $value in $Body_2 {
                      #{$property}: #{$value};
                    }
                  }
                }
              }
            }

            &__inf {
              display: flex;
              padding: 0.5rem 0rem;
              flex-direction: column;
              align-items: flex-start;
              gap: 0.25rem;

              &__menu_item {
                display: flex;
                width: 15.75rem;
                padding: 0.5rem;
                align-items: center;
                gap: 0.5rem;

                &__icon {
                  width: 1rem;
                  height: 1rem;
                }

                &__item {
                  @each $property, $value in $Body_1 {
                    #{$property}: #{$value};
                  }

                  &__logout {
                    color: var(--error);

                    &:hover {
                      color: var(--error);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  button {
    border: none;
    background: none;
    outline: none;
    padding: 0;
  }
}
